
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class VImageUpload extends Vue {
  @Prop({ type: Boolean, default: false }) public disabled?: boolean;
  @Prop({ type: Boolean, default: false }) public multiple?: boolean;
  @Prop({ type: Array, default: () => [] }) public errorMessages?: any[];
  @Prop({ type: String }) public name?: string | TranslateResult;
  @Prop({ type: String, default: 'image/*' }) public accept?: string;
  @Prop({ type: String }) public label?: string;

  public $refs!: {
    fileInput: any;
  };

  public images: any = null;
  public showDialog: boolean = false;
  public isManualUpload: boolean = false;
  public showSingleImageAlert: boolean = false;
  public showNotAnImageAlert: boolean = false;

  public onInputClick(event: any) {
    if (!this.isManualUpload) {
      event.preventDefault();
      return (this.showDialog = true);
    }
  }

  public manualUpload() {
    this.isManualUpload = true;

    if (this.$refs?.fileInput?.$refs?.input) {
      this.$refs.fileInput.$refs.input.click();
    }
    this.isManualUpload = false;
  }

  public dragOver(event: any) {
    event.dataTransfer.dropEffect = 'copy';
  }

  public dragLeave(event: any) {
    event.dataTransfer.dropEffect = 'none';
  }

  public dropFile(event: any) {
    const droppedImages = event.dataTransfer.files;

    if (!droppedImages?.length) {
      return;
    }

    if (!this.testImages(droppedImages)) {
      this.showNotAnImageAlert = true;

      setTimeout(() => {
        this.showNotAnImageAlert = false;
      }, 1500);
    } else if (!this.multiple && droppedImages.length > 1) {
      this.showSingleImageAlert = true;

      setTimeout(() => {
        this.showSingleImageAlert = false;
      }, 1500);
    } else {
      this.images = this.multiple ? [...droppedImages] : droppedImages[0];
    }
  }

  public testImages(files: any[]) {
    for (const file of files) {
      if (!file.type.match(this.accept)) {
        return false;
      }
    }

    return true;
  }

  @Watch('images')
  public onImagesChange() {
    this.showDialog = false;
    this.$emit('input', this.images);
    this.$emit('change', this.images);
  }
}
